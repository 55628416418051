import * as zod from 'zod';

export const GlobalVariableData = zod.object({
  id: zod.string(),
  name: zod.string(),
  initialValue: zod.any().optional(),
  created: zod.string(),
  updated: zod.string(),
});
export type GlobalVariableData = zod.infer<typeof GlobalVariableData>;
